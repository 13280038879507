import React from "react";
import Layout from "../components/layout";
// import SEO from "../components/seo";
import Lottie from "react-lottie";
import animationData from "../lottiefiles/29142-error-404.json";
// import abductionIllustration from "../images/abduction-illustration.svg";
import { Link } from "gatsby";

export default function App() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Layout>
      <div className="m-auto">
        <div className=" m-auto flex flex-row">
          <Lottie
            className="w-1/5"
            options={defaultOptions}
            height={600}
            width={600}
          />
        </div>

        <div className="flex flex-row w-full m-auto text-center mb-20">
          <Link to={"/"} className="flex mx-auto text-cente">
            <button
              type="button"
              className="inline-flex items-center px-12 py-2 border border-transparent rounded-3xl shadow-sm text-md font-medium text-white bg-black hover:bg-blue-800 focus:outline-none "
            >
              Home
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}
